import React from 'react'

function isInArray(value, array) {
   return array.indexOf(value) > -1;
}

const car_field_name_ids = [
   'branch_id',
   'car_status_id',
   'car_brand_id',
   'car_model_id',
   'car_sub_model_id',
   'car_gear_id',
   'car_color_id',
   'car_year_id',
   'province_new_id',

   'car_type_id',
   'car_structure_id',
   'car_hassock_id',
   'car_system_drive_id',
   'car_type_engine_id',

   'insurance_type_id',
   'insurance_company_id',
   'compulsory_insurance_company',

   'liciense_status_id',
   'change_book_status_id',
   'car_adapt_status_id',

   'finance_id',
   'finance_floorplan_id',
    'deposit_registration_id'
]

export const DataLogUtil = {
   getIdNameValueCarEdit(fieldName, value, arrayLists) {
      if(arrayLists.length === 0){
         return value
      }

      if (isInArray(fieldName, car_field_name_ids)) {
         try{

            let nameValueField = fieldName.replace("_id", "_name");
            if (fieldName === 'province_new_id') {
               nameValueField = 'province_name'
               fieldName = 'province_id'
            }else if (fieldName === 'compulsory_insurance_company') {
               nameValueField = 'insurance_company_name'
               fieldName = 'insurance_company_id'
            }else if (fieldName === 'finance_id') {
               nameValueField = 'bank_name'
               fieldName = 'bank_id'
            }

            let arr_filter = arrayLists.filter(obj => {
               let val = obj[String(fieldName)]
               if (val === Number(value)) {
                  return obj
               }
            })[0];

            let name = arr_filter[String(nameValueField)]
            // console.log(fieldName, '=' ,value, ':', name)
            // console.log(fieldName, nameValueField, value, typeof value, name)
            return value+':'+name.trim()
         }catch (e) {
            return value+': -'
         }
      }else {
         return value
      }
   }
}
