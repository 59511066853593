import axios from "axios";
import {APIURL} from "../config/config";

export const addDataLog = async (dataLog) => {
  // company_setting_id
  console.log(dataLog)
  try{
    let result = await axios.post(APIURL + '/data_log', dataLog)
    return result.data
  }catch (e) {
    console.log('error: ', e)
    return null
  }
}